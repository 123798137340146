import _ from "lodash"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import isBetween from "dayjs/plugin/isBetween"
import minMax from "dayjs/plugin/minMax"
import { problemFeatures, routeFeatures } from "../config/features"
import {
  frenchBoulderingGrades,
  usBoulderingGrades,
  frenchRouteGrades,
  usRouteGrades,
} from "../config/grades"

import {
  getGradeObject,
  getGradeLabel,
  getColorRange,
  getImageRange,
  getRangeAroundGrades,
} from "./grades"
import { getGreyHues, getBlueHues } from "./colors"
import { metersToFeet } from "./measure"

// add min max functionality
dayjs.extend(minMax)
dayjs.extend(LocalizedFormat)
dayjs.extend(isBetween)

function getChartItems({
  climbSends,
  chartVals,
  getVal,
  comparator,
  showUnknown = true,
}) {
  let children = climbSends.reduce(
    (ar, feat) => {
      const prob = feat.problem || feat.route || feat
      let hasAngle = false
      chartVals.forEach((angle) => {
        const angVal = angle.value
        const probVal = getVal ? getVal(prob) : prob[angVal]
        const passesCompare = comparator
          ? comparator(probVal, angle)
          : prob[angVal]
        if (passesCompare) {
          hasAngle = true
          const childObj = _.find(ar, { value: angVal })
          if (childObj) {
            childObj.size += 1
          } else {
            ar.push({
              value: angVal,
              minValue: angle.minValue,
              maxValue: angle.maxValue,
              title: angle.title,
              size: 1,
              radius: 25,
            })
          }
        }
      })
      if (hasAngle === false && showUnknown) {
        _.find(ar, { value: "unknown" }).size += 1
      }
      return ar
    },
    [
      {
        value: "unknown",
        title: "Unknown",
        size: 0,
        radius: 25,
      },
    ],
  )

  children = _.sortBy(children, (c) => {
    const ind = problemFeatures.map((f) => f.featureKey).indexOf(c.value)
    return c.minValue || (ind === -1 ? 1000 : ind)
  })

  const colors = getBlueHues(children.length)
  const activeColors = getGreyHues(children.length)
  return {
    children: children.map((child, ind) => ({
      ...child,
      color: colors[ind],
      colorValue: colors[ind],
      activeColor: activeColors[ind],
    })),
  }
}

export function getAngleData(climbSends, curFeatureType) {
  if (!climbSends) {
    return null
  }
  const feats = curFeatureType === "problem" ? problemFeatures : routeFeatures
  const angles = feats
    .filter((f) => f.group === "angle")
    .map((f) => ({
      title: f.title,
      value: f.featureKey,
    }))

  return getChartItems({ climbSends, chartVals: angles })
}

export function getSetterData(climbSends) {
  if (!climbSends) {
    return null
  }
  const allSetters = climbSends
    .map((climbSend) => {
      const climb = climbSend.problem || climbSend.route || climbSend
      return climb.setter
    })
    .filter((set) => !!set)
  const setters = _.uniqBy(allSetters, (set) => set._id).map((setter) => ({
    value: setter.name,
    title: setter.name,
  }))

  return getChartItems({
    climbSends,
    chartVals: setters,
    getVal: (cl) => cl.setter?.name,
    comparator: (val, obj) => val === obj.value,
    showUnknown: false,
  })
}

export function getGymGuideData(climbSends) {
  if (!climbSends) {
    return null
  }
  const allGyms = climbSends
    .map((climbSend) => {
      const climb = climbSend.problem || climbSend.route || climbSend
      return climb.gym || climb.guide
    })
    .filter((set) => !!set)
  const gyms = _.uniqBy(allGyms, (set) => set._id).map((gym) => ({
    value: gym.name,
    title: gym.name,
  }))

  return getChartItems({
    climbSends,
    chartVals: gyms,
    getVal: (cl) => (cl.gym ? cl.gym.name : cl.guide ? cl.guide.name : null),
    comparator: (val, obj) => val === obj.value,
  })
}

export function getLandingData(climbSends, curFeatureType) {
  if (!climbSends) {
    return null
  }
  const feats = problemFeatures
  const landings = feats
    .filter((f) => f.group === "landing")
    .map((f) => ({
      title: f.title,
      value: f.value,
    }))

  return getChartItems({
    climbSends,
    chartVals: landings,
    getVal: (cl) => cl.landing,
    comparator: (val, obj) => val === obj.value,
  })
}

export function getSunData(climbSends, curFeatureType) {
  if (!climbSends) {
    return null
  }
  const feats = curFeatureType === "problem" ? problemFeatures : routeFeatures
  const suns = feats
    .filter((f) => f.group === "sun")
    .map((f) => ({
      title: f.title,
      value: f.value,
    }))

  return getChartItems({
    climbSends,
    chartVals: suns,
    getVal: (cl) => cl.sun,
    comparator: (val, obj) => {
      return val === obj.value
    },
  })
}

export function getIndoorData(climbSends, curFeatureType) {
  if (!climbSends) {
    return null
  }
  const indoorOutFeats = [
    {
      value: "indoor",
      title: "Indoor",
    },
    {
      value: "outdoor",
      title: "Outdoor",
    },
  ]
  return getChartItems({
    climbSends,
    chartVals: indoorOutFeats,
    getVal: (cl) => !!cl.gym,
    comparator: (val, obj) => {
      return (
        (val && obj.value === "indoor") || (!val && obj.value === "outdoor")
      )
    },
  })
}

export function getEnergyData(climbSends, curFeatureType) {
  if (!climbSends) {
    return null
  }
  const feats = curFeatureType === "problem" ? problemFeatures : routeFeatures
  const energys = feats
    .filter((f) => f.group === "energy")
    .map((f) => ({
      title: f.title,
      value: f.featureKey,
    }))

  return getChartItems({ climbSends, chartVals: energys })
}
export function getRopeworkData(climbSends, curFeatureType) {
  if (!climbSends) {
    return null
  }
  const feats = curFeatureType === "problem" ? problemFeatures : routeFeatures
  const ropeworks = feats
    .filter((f) => f.group === "ropework")
    .map((f) => ({
      title: f.title,
      value: f.featureKey,
    }))

  return getChartItems({ climbSends, chartVals: ropeworks })
}

export function getHeightData(climbSends) {
  if (!climbSends) {
    return null
  }

  // get problem characteristics corresponding to height
  const heights = problemFeatures
    .filter((f) => f.group === "height")
    .map((f) => ({
      title: f.title,
      value: f.value,
    }))
  return getChartItems({
    climbSends,
    chartVals: heights,
    getVal: (cl) => cl.height,
    comparator: (val, obj) => val === obj.value,
  })
}

export function getMovesData(climbSends) {
  if (!climbSends) {
    return null
  }

  const numMoves = [
    {
      title: "1 - 2",
      minValue: 1,
      maxValue: 2,
    },
    {
      title: "3 - 4",
      minValue: 3,
      maxValue: 4,
    },
    {
      title: "5 - 6",
      minValue: 5,
      maxValue: 6,
    },
    {
      title: "7 - 8",
      minValue: 7,
      maxValue: 8,
    },
    {
      title: "9 - 10",
      minValue: 9,
      maxValue: 10,
    },
    {
      title: "> 10",
      minValue: 11,
      maxValue: 100,
    },
  ]
  return getChartItems({
    climbSends,
    chartVals: numMoves,
    getVal: (climb) => climb.numberOfSignificantMoves,
    comparator: (val, obj) => val && obj.minValue <= val && obj.maxValue >= val,
  })
}

export function getGearData(climbSends) {
  if (!climbSends) {
    return null
  }

  // get route characteristics corresponding to energy
  const protections = routeFeatures
    .filter((f) => f.group === "protection")
    .map((f) => ({
      title: f.title,
      value: f.value,
    }))
  return getChartItems({
    climbSends,
    chartVals: protections,
    getVal: (climb) => climb.protection,
    comparator: (val, obj) => val && val === obj.value,
  })
}

export function getProtectionData(climbSends) {
  if (!climbSends) {
    return null
  }

  // get route characteristics corresponding to energy
  const safetys = routeFeatures
    .filter((f) => f.group === "safety")
    .map((f) => ({
      title: f.title,
      value: f.value,
    }))
  return getChartItems({
    climbSends,
    chartVals: safetys,
    getVal: (climb) => climb.safety,
    comparator: (val, obj) => val && val === obj.value,
  })
}

export function getPitchesData(climbSends) {
  if (!climbSends) {
    return null
  }

  const numPitches = [
    {
      title: "1",
      minValue: 1,
      maxValue: 1,
    },
    {
      title: "2 - 4",
      minValue: 2,
      maxValue: 4,
    },
    {
      title: "5 - 10",
      minValue: 5,
      maxValue: 10,
    },
    {
      title: "> 10",
      minValue: 11,
      maxValue: 100,
    },
  ]
  return getChartItems({
    climbSends,
    chartVals: numPitches,
    getVal: (feat) => (feat.pitches ? feat.pitches.length : 1),
    comparator: (val, obj) => val && obj.minValue <= val && obj.maxValue >= val,
  })
}

export function getRouteHeightData(climbSends, gradeType) {
  if (!climbSends) {
    return null
  }

  let routeHeights = [
    {
      title: "0 - 30 feet",
      minValue: 0,
      maxValue: 30,
    },
    {
      title: "31 - 60 feet",
      minValue: 31,
      maxValue: 60,
    },
    {
      title: "61 - 90 feet",
      minValue: 61,
      maxValue: 90,
    },
    {
      title: "91 - 120 feet",
      minValue: 90,
      maxValue: 120,
    },
    {
      title: "121 - 500 feet",
      minValue: 121,
      maxValue: 500,
    },
    {
      title: "501 - 1000 feet",
      minValue: 501,
      maxValue: 1000,
    },
    {
      title: "1001 - 2000 feet",
      minValue: 1001,
      maxValue: 2000,
    },
    {
      title: "> 2000",
      minValue: 2001,
      maxValue: 10000,
    },
  ]
  if (gradeType === "french") {
    routeHeights = [
      {
        title: "0 - 10 meters",
        minValue: 0,
        maxValue: 10,
      },
      {
        title: "11 - 20 meters",
        minValue: 11,
        maxValue: 20,
      },
      {
        title: "21 - 30 meters",
        minValue: 21,
        maxValue: 30,
      },
      {
        title: "31 - 40",
        minValue: 31,
        maxValue: 40,
      },
      {
        title: "41 - 200 meters",
        minValue: 41,
        maxValue: 200,
      },
      {
        title: "201 - 400 meters",
        minValue: 201,
        maxValue: 400,
      },
      {
        title: "401 - 800 meters",
        minValue: 401,
        maxValue: 800,
      },
      {
        title: "> 800 meters",
        minValue: 801,
        maxValue: 10000,
      },
    ]
  }
  return getChartItems({
    climbSends,
    chartVals: routeHeights,
    getVal: (feat) =>
      gradeType === "french" ? feat.height : metersToFeet(feat.height),
    comparator: (val, obj) => val && obj.minValue <= val && obj.maxValue >= val,
  })
}

export function getNumberOfBoltsData(climbSends) {
  if (!climbSends) {
    return null
  }

  const numBolts = [
    {
      title: "< 5",
      minValue: 1,
      maxValue: 5,
    },
    {
      title: "5 - 7",
      minValue: 5,
      maxValue: 7,
    },
    {
      title: "8 - 10",
      minValue: 8,
      maxValue: 10,
    },
    {
      title: "11 - 13",
      minValue: 10,
      maxValue: 13,
    },
    {
      title: "14 - 16",
      minValue: 14,
      maxValue: 16,
    },
    {
      title: "> 16",
      minValue: 17,
      maxValue: 100,
    },
  ]

  return getChartItems({
    climbSends,
    chartVals: numBolts,
    getVal: (feat) => feat.numBolts,
    comparator: (val, obj) => val && obj.minValue <= val && obj.maxValue >= val,
  })
}

// colorMultiplier multiplies the length of the children when getting colors so that
// all the colors are darker
export function getCharacteristicsData(
  climbSends,
  curFeatureType,
  colorMultiplier = 1,
) {
  if (!climbSends) {
    return null
  }

  const groups = ["hold-type", "footwork", "movement"]

  const feats = curFeatureType === "problems" ? problemFeatures : routeFeatures

  const characteristics = feats
    .filter((f) => groups.includes(f.group))
    .map((f) => ({
      title: f.title,
      value: f.featureKey,
    }))

  const children = climbSends.reduce((ar, feat) => {
    const prob = feat.type === "send" ? feat.problem || feat.route : feat
    _.each(characteristics, (characteristic) => {
      const charVal = characteristic.value
      if (prob?.[charVal]) {
        const childObj = _.find(ar, { value: charVal })
        if (childObj) {
          childObj.size += 1
        } else {
          ar.push({
            value: charVal,
            title: characteristic.title,
            size: 1,
            radius: 25,
          })
        }
      }
    })
    return ar
  }, [])
  const colors = getBlueHues(children.length * colorMultiplier)
  const activeColors = getGreyHues(children.length)
  return {
    color: 255,
    children: children.map((child, ind) => ({
      ...child,
      color: colors[ind],
      colorValue: colors[ind],
      activeColor: activeColors[ind],
    })),
  }
}

export function getGymGradeRange({ gym, gradeType, curFeatureType }) {
  const problemGradeColors = gym?.problemGradeColors
  const routeGradeColors = gym?.routeGradeColors
  const showProblemColors = problemGradeColors?.length && !gym.isShowingGrades
  const showRouteColors = routeGradeColors?.length && !gym.isShowingGrades
  // prettier-ignore
  // https://github.com/prettier/prettier/issues/5814
  let gradesLabels =
    curFeatureType === "problems" || curFeatureType === "problem"
      ? showProblemColors
        ? problemGradeColors.map(obj => ({...obj, value: obj.maxGrade, label: obj.name}))
        : gradeType === "french"
          ? frenchBoulderingGrades
          : usBoulderingGrades
      : showRouteColors
        ? routeGradeColors.map(obj => ({...obj, value: obj.maxGrade, label: obj.name}))
        : gradeType === "french"
          ? frenchRouteGrades
          : usRouteGrades
  gradesLabels =
    showProblemColors || showRouteColors
      ? gradesLabels
      : _.slice(gradesLabels, 0, gradesLabels.length - 2)
  return gradesLabels
}

function getClimbGradeArray({
  gradesLabels,
  climbSends,
  isOverlappingGrades,
  activeTags = [],
}) {
  const initAr = gradesLabels.map((g, ind) => ({
    value: g.value,
    minValue: gradesLabels[ind - 1] ? gradesLabels[ind - 1].value + 0.5 : 0,
    x: g.label,
    gradeColor: g.color,
    gradeName: g.name,
    y: 0,
  }))
  // loop through sends and reduce to array of objs with grade and number
  // of sends at that grade
  // and tags if any
  let children = _.sortBy(climbSends, ["setterGrade", "grade"])
    .reduce((ar, feat) => {
      const prob = feat.problem || feat.route || feat
      const { grade, setterGrade, gradeColor, gradeName } = prob
      const gr = setterGrade || grade
      _.each(gradesLabels, (gradeLabel) => {
        if (
          gr <= gradeLabel.value &&
          (!isOverlappingGrades || gradeName === gradeLabel.name)
        ) {
          const childObj = _.find(ar, { value: gradeLabel.value })
          childObj.y += 1
          // add tags if any
          const probTags = activeTags.filter((tag) =>
            prob.customCharacteristics?.includes(tag._id),
          )
          if (probTags.length > 0) {
            childObj.numWithTags = childObj.numWithTags
              ? childObj.numWithTags + 1
              : 1
          }
          _.each(probTags, (tag) => {
            if (childObj[tag.name]) {
              childObj[tag.name] += 1
            } else {
              childObj[tag.name] = 1
            }
          })
          return false
        }
      })
      return ar
    }, initAr)
    .map((d) => {
      return {
        ...d,
        "Rest of the Climbs": d.y - (d.numWithTags || 0),
      }
    })
  // remove max and min children that have zero y vals
  const yValsAr = children.map((c) => c.y)
  const start = _.findIndex(yValsAr, (val) => val !== 0) - 1
  const end = _.findLastIndex(yValsAr, (val) => val !== 0) + 1
  children =
    start < 0 ? _.slice(children, 0, end) : _.slice(children, start, end)
  return children
}

export function getGradesData(
  climbSends,
  gradeType,
  curFeatureType,
  gym,
  activeTags = [],
) {
  if (!climbSends?.length) {
    return null
  }
  const filteredSends = climbSends.filter((s) => {
    return (
      !!s &&
      (s.type === "send"
        ? s.problem
          ? s.problem?.setterGrade || s.problem?.grade
          : s.route?.setterGrade || s.route?.grade
        : s.setterGrade || s.grade)
    )
  })

  const gradesLabels = getGymGradeRange({
    gym,
    gradeType,
    curFeatureType,
  })
  const children = getClimbGradeArray({
    gradesLabels,
    climbSends: filteredSends,
    isOverlappingGrades: gym?.isOverlappingGrades,
    activeTags,
  })
  const colors = getBlueHues(children.filter((c) => c.y !== 0).length)
  const activeColors = getGreyHues(children.filter((c) => c.y !== 0).length)
  let colorInd = -1
  return {
    children: _(children)
      .sortBy("y")
      .reverse()
      .map((child) => {
        if (child.y > 0) {
          colorInd += 1
        }
        return {
          ...child,
          color: colors[colorInd],
          colorValue: colors[colorInd],
          activeColor: activeColors[colorInd],
        }
      })
      .sortBy("value")
      .value(),
  }
}

export function getGradeColorsData(climbSends, curFeatureType, gym) {
  if (!climbSends?.length) {
    return null
  }
  const filteredSends = _.filter(climbSends, (s) => {
    return s.type === "send"
      ? s.problem
        ? s.problem?.setterGrade || s.problem?.grade
        : s.route?.setterGrade || s.route?.grade
      : s.setterGrade || s.grade
  })

  const gradesLabels = getGymGradeRange({
    gym,
    curFeatureType,
  })
  const children = getClimbGradeArray({
    gradesLabels,
    climbSends: filteredSends,
    isOverlappingGrades: gym?.isOverlappingGrades,
  })
  const colors = getBlueHues(children.filter((c) => c.y !== 0).length)
  const activeColors = getGreyHues(children.filter((c) => c.y !== 0).length)
  let colorInd = -1
  return {
    children: _(children)
      .sortBy("y")
      .reverse()
      .map((child) => {
        if (child.y > 0) {
          colorInd += 1
        }
        return {
          ...child,
          color: colors[colorInd],
          colorValue: colors[colorInd],
          activeColor: activeColors[colorInd],
        }
      })
      .sortBy("value")
      .value(),
  }
}

export function getListOfSendsAndAttempts(session) {
  const mergedSendAttempts = getMergedSendAttempts(session)
  const sentClimbIds = (session?.sends ?? []).map(
    (s) => s?.problem?._id || s?.route?._id,
  )
  const gradesAdded = []
  return _.sortBy(mergedSendAttempts, "date")
    .filter((tr) => {
      const climbId = tr?.problem?._id || tr?.route?._id
      const isInSends =
        !!climbId ||
        session.sends.some((s) => {
          return s.grade === tr.grade && dayjs(tr.date).isBefore(s.date)
        })
      const isInAttempts = gradesAdded.includes(tr.grade)
      if (!isInSends && isInAttempts) {
        gradesAdded.push(tr.grade)
      }
      return (
        !tr.isAttempts ||
        (!sentClimbIds.includes(climbId) && climbId) ||
        !isInSends
      )
      // finally merge tries together that aren't sends and have no climb id
    })
    .reduce((acc, tr) => {
      if (tr.isAttempts && !tr.problem && !tr.route) {
        const similarAtt = acc.find(
          (at) =>
            at.isAttempts && !at.problem && !at.route && at.grade === tr.grade,
        )
        if (similarAtt) {
          similarAtt.tries = similarAtt.tries + tr.tries
          similarAtt.attemptIds = _.uniq([
            ...similarAtt.attemptIds,
            ...tr.attemptIds,
          ])
          return acc
        }
      }
      return [...acc, tr]
    }, [])
}

function getSendTryGradeArray({ gradesLabels, sends, isOverlappingGrades }) {
  const initAr = gradesLabels.map((g, ind) => ({
    value: g.value,
    minValue: gradesLabels[ind - 1] ? gradesLabels[ind - 1].value + 0.5 : 0,
    x: g.label,
    gradeColor: g.color,
    gradeImage: g.image,
    gradeName: g.name || g.label,
    sends: {
      value: 0,
    },
    attempts: {
      value: 0,
    },
  }))

  // loop through sends and reduce to array of objs with grade and number
  // of sends at that grade
  // unless gym has overlapping grades
  const children = isOverlappingGrades
    ? _.sortBy(sends, "grade").reduce((ar, send) => {
        const prob = send.problem || send.route
        const { gradeColor, gradeName } = prob
        _.each(gradesLabels, (gradeLabel) => {
          const label = gradeLabel.label || gradeLabel.name
          if (
            (gradeColor && gradeColor === gradeLabel.color) ||
            gradeName === label
          ) {
            const childObj = _.find(
              ar,
              gradeColor
                ? { gradeColor: gradeLabel.color }
                : { gradeName: label },
            )
            if (!childObj) {
              return ar
            }
            childObj.sends.value += send.isAttempts ? 0 : 1
            childObj.attempts.value += send.isAttempts ? send.tries : 0
            return false
          }
        })
        return ar
      }, initAr)
    : _.sortBy(sends, "grade").reduce((ar, send) => {
        const prob = send.problem || send.route
        const grade = prob?.setterGrade || prob?.grade || send.grade
        _.each(gradesLabels, (gradeLabel) => {
          if (grade <= gradeLabel.value) {
            const childObj = _.find(ar, { value: gradeLabel.value })
            childObj.sends.value += send.isAttempts ? 0 : 1
            childObj.attempts.value += send.isAttempts ? send.tries : 0
            return false
          }
        })
        return ar
      }, initAr)
  // remove max and min children that have zero y vals
  const yValsAr = children.map((c) => c.sends.value + c.attempts.value)
  const start = _.findIndex(yValsAr, (val) => val !== 0)
  const end = _.findLastIndex(yValsAr, (val) => val !== 0) + 1
  return start < 0 ? _.slice(children, 0, end) : _.slice(children, start, end)
}

export function getSessionTryData({ sends, gradeType, curFeatureType, gym }) {
  if (!sends) {
    return []
  }
  const gradesLabels = getGymGradeRange({
    gym,
    gradeType,
    curFeatureType,
  })
  const data = getSendTryGradeArray({
    gradesLabels,
    sends,
    isOverlappingGrades: gym?.isOverlappingGrades,
  })
  return _(data).sortBy("value").value()
}

// merges all unsent attempts into send objects
export function getMergedSendAttempts(session) {
  if (!session) {
    return []
  }
  const gradeType = session.user?.isFrenchGrades ? "french" : "us"
  const mergedAttempts = (session.attempts || [])
    .filter((at) => !!at)
    .reduce((acc, att) => {
      const climb = att.route || att.problem
      const foundAtt = acc.find((prev) => {
        const prevClimb = prev?.problem || prev?.route
        return climb && prevClimb && climb._id === prevClimb._id
      })
      if (foundAtt) {
        foundAtt.tries += 1
        foundAtt.attemptIds.push(att._id)
      } else if (att) {
        acc.push({
          ...att,
          name: `${session.user.firstName} tried ${
            climb
              ? climb.name
              : `a ${getGradeLabel(att.grade, att.climbType, gradeType)}`
          }`,
          type: "send",
          isIndoor: !!session.gym,
          isAttempts: true,
          tries: 1,
          attemptIds: [att._id],
          user: session.user,
          sessionId: session._id,
          sendType: att.problem
            ? "problem"
            : att.route
            ? "route"
            : att.climbType,
        })
      }
      return acc
    }, [])
  return [...(session.sends || []), ...mergedAttempts]
}

export function getWeeksBucket(minDate, maxDate) {
  let curDate = dayjs(minDate).startOf("isoWeek")
  maxDate = dayjs.min(maxDate, dayjs().endOf("isoWeek"))
  const weekAr = []
  let ind = 0
  while (curDate.valueOf() < maxDate.valueOf()) {
    const difSt = curDate.diff(minDate)
    const minD = dayjs.max(dayjs(curDate), minDate)
    curDate = dayjs(curDate.add(1, "week").subtract(1, "ms"))
    const maxD = dayjs.min(curDate, dayjs(maxDate).endOf("day"))
    const difEn = maxDate.diff(curDate)
    const ast = difSt < 0 || difEn < 0 ? "*" : ""
    weekAr.push({
      minDate: minD,
      maxDate: maxD,
      y: 0,
      x: `${minD.format("M/D")}-${maxD.format("M/D")}${ast}`,
      ind,
    })
    curDate = curDate.add(1, "ms")
    ind += 1
  }
  return weekAr
}

// break time into weeks and then put sends into buckets
export function getWeeksData(sends, minDate, maxDate) {
  const weeksAr = getWeeksBucket(minDate, maxDate)
  const children = _(sends)
    .sortBy((s) => new Date(s.date))
    .reduce((acc, send) => {
      if (!send || (!send.problem && !send.route)) {
        return acc
      }
      const points = send.problem ? send.problem.grade : send.route.grade
      const date = dayjs(send.date)
      const weekInd = _.findIndex(acc, (week) => {
        const isBtwn = date.isBetween(week.minDate, week.maxDate)
        return isBtwn
      })
      if (weekInd !== -1) {
        acc[weekInd].y += points
      }
      return acc
    }, weeksAr)
  const colors = getBlueHues(children.filter((c) => c.y !== 0).length)
  const activeColors = getGreyHues(children.filter((c) => c.y !== 0).length)
  let colorInd = -1
  return {
    children: _(children)
      .map((child) => {
        if (child.y > 0) {
          colorInd += 1
        }
        return {
          ...child,
          color: colors[colorInd],
          colorValue: colors[colorInd],
          activeColor: activeColors[colorInd],
        }
      })
      .sortBy("ind")
      .value(),
  }
}

export function filterFeatures(
  features,
  {
    anglesFilters = [],
    heightsFilters = [],
    movesFilters = [],
    characteristicsFilters = [],
    gradesFilters = [],
    gradeNamesFilters = [],
    gearFilters = [],
    pitchesFilters = [],
    protectionFilters = [],
    energyFilters = [],
    routeHeightFilters = [],
    numberOfBoltsFilters = [],
    landingFilters = [],
    sunFilters = [],
    indoorFilters = [],
    weeksFilters = [],
    settersFilters = [],
    gymGuidesFilters = [],
    ropeworkFilters = [],
  },
  curFeatureType,
  weeksData,
  gradeType,
) {
  const allAngles = _(problemFeatures)
    .filter({ group: "angle" })
    .map("featureKey")
    .value()
  return features.filter((feat) => {
    const featureType =
      curFeatureType[curFeatureType.length - 1] === "s"
        ? curFeatureType.slice(0, -1)
        : curFeatureType
    const feature = feat.type === "send" ? feat.problem || feat.route : feat

    if (!feature) {
      return false
    }
    const sendDate = feat.type === "send" ? dayjs(feat.date) : null

    const hasIndoor =
      feat.type !== "send" ||
      indoorFilters.length === 0 ||
      (indoorFilters.includes("indoor") && !!feature.gym) ||
      (indoorFilters.includes("outdoor") && !feature.gym)
    const hasAngle =
      anglesFilters.length === 0 ||
      _.find(anglesFilters, (f) => feature[f]) ||
      (!_.find(allAngles, (f) => feature[f]) &&
        anglesFilters.includes("unknown"))
    const hasCharacter =
      characteristicsFilters.length === 0 ||
      _.find(characteristicsFilters, (f) => feature[f])
    const gradeObj = getGradeObject(
      feature.setterGrade || feature.grade,
      featureType,
      gradeType,
      feature.gym,
    )
    const hasGrade =
      gradesFilters.length === 0 ||
      (gradeObj && gradesFilters.includes(Math.round(gradeObj.value)))
    const hasGradeName =
      gradeNamesFilters.length === 0 ||
      gradeNamesFilters.includes(feature.gradeName)

    const isHeight =
      heightsFilters.length === 0 ||
      heightsFilters.includes(feature.height) ||
      (!feature.height && heightsFilters.includes("unknown"))
    const hasMoves =
      movesFilters.length === 0 ||
      _.find(
        movesFilters,
        (m) =>
          m <= feature.numberOfSignificantMoves &&
          m + 1 >= feature.numberOfSignificantMoves,
      ) !== undefined
    const hasGear =
      gearFilters.length === 0 ||
      gearFilters.includes(feature.protection) ||
      (!feature.protection && gearFilters.includes("unknown"))
    const hasPitches =
      pitchesFilters.length === 0 ||
      _.find(pitchesFilters, (p) => {
        const len = feature.pitches ? feature.pitches.length : 1
        return p <= len && p + 1 >= len
      }) !== undefined
    const hasProtection =
      protectionFilters.length === 0 ||
      protectionFilters.includes(feature.safety) ||
      (!feature.safety && protectionFilters.includes("unknown"))
    const hasSun =
      sunFilters.length === 0 ||
      sunFilters.includes(feature.sun) ||
      (!feature.sun && sunFilters.includes("unknown"))
    const hasLanding =
      landingFilters.length === 0 ||
      landingFilters.includes(feature.landing) ||
      (!feature.sun && landingFilters.includes("unknown"))
    const isEnergy =
      energyFilters.length === 0 || _.find(energyFilters, (f) => feature[f])
    const isRopework =
      ropeworkFilters.length === 0 || _.find(ropeworkFilters, (f) => feature[f])
    const isRouteHeight =
      routeHeightFilters.length === 0 ||
      routeHeightFilters.includes(feature.height) ||
      (!feature.height && routeHeightFilters.includes("unknown"))
    const isInSelectedWeeks =
      !weeksFilters ||
      weeksFilters.length === 0 ||
      _.find(weeksFilters, (ind) => {
        const minD = weeksData.children[ind]?.minDate ?? new Date()
        const maxD = weeksData.children[ind]?.maxDate ?? new Date()
        return sendDate.isBetween(minD, maxD)
      })
    const isFromSetter =
      settersFilters.length === 0 ||
      settersFilters.includes(feature.setter?.name)
    const isInGymGuide =
      gymGuidesFilters.length === 0 ||
      gymGuidesFilters.includes(feature.gym?.name) ||
      gymGuidesFilters.includes(feature.guide?.name)
    // const hasNumberOfBolts = numberOfBoltsFilters.length === 0 || numberOfBoltsFilters.includes(feature.numberOfBolts)
    const isReturning =
      hasIndoor &&
      hasAngle &&
      hasLanding &&
      hasSun &&
      hasCharacter &&
      hasGrade &&
      hasGradeName &&
      hasSun &&
      isFromSetter &&
      isInGymGuide &&
      !!isInSelectedWeeks &&
      ((featureType === "problem" &&
        feature.type === "problem" &&
        isHeight &&
        hasLanding &&
        hasMoves) ||
        (featureType === "route" &&
          feature.type === "route" &&
          hasGear &&
          hasPitches &&
          hasProtection &&
          isEnergy &&
          isRopework &&
          isRouteHeight))
    return isReturning
  })
}

export function getGradeChartLabelData({ gradeColors, bandwidth, gradeRange }) {
  let minGrade = 0
  let curInd = 0
  const labelPositions =
    gradeColors &&
    gradeColors.map((col) => {
      const gradesUnder = gradeRange.filter((obj) => {
        const isUnder = col.maxGrade >= obj.value && minGrade < obj.value
        minGrade = isUnder ? Math.max(obj.value, minGrade) : minGrade
        return isUnder
      })
      const ind = curInd + (gradesUnder.length - 1) / 2
      curInd += gradesUnder.length
      return {
        position: ind === Math.round(ind) ? 0 : (-1 * bandwidth) / 2,
        index: Math.ceil(ind),
      }
    })

  return gradeRange.map((gradeObj, index) => {
    const colorObj = gradeColors
      ? gradeColors.find((col) => col.maxGrade >= gradeObj.value)
      : null
    const posObj =
      labelPositions && labelPositions.find((obj) => obj.index === index)
    const label =
      posObj && colorObj ? colorObj.name : colorObj ? null : gradeObj.label

    const offset = 9 - gradeRange.length
    return {
      colorObj,
      posObj,
      label,
      offset,
      index,
      gradeObj,
    }
  })
}

export function getGradeChartData({
  grade,
  grades,
  gradeColor,
  gradeName,
  gradeType,
  climbType,
  gym,
  highlightColor,
  headingColor,
}) {
  const hasColors =
    !gym?.isShowingGrades && gym?.[`${climbType}GradeColors`]?.[0]?.color
  const hasImages =
    !gym?.isShowingGrades && gym?.[`${climbType}GradeColors`]?.[0]?.image
  // if no grades from users (wikiboulder problems or newly set gym problems
  // with no user grades) then use problem grade as single element in gradesAr
  const gradesAr = grades.slice()
  if (gradesAr.length === 0) {
    gradesAr.push({
      user: null,
      grade,
    })
  }
  const gradeRange = getRangeAroundGrades({
    gradesAr,
    type: climbType,
    gradeType,
    gym,
    color: gradeColor,
    name: gradeName,
  })
  const gradeObjs = gym?.[`${climbType}GradeColors`]
  const gradeColors = hasColors ? getColorRange(gradeRange, gradeObjs) : null
  const gradeImages = hasImages ? getImageRange(gradeRange, gradeObjs) : null
  const gradeValues = _.map(gradeRange, "value")

  const gradesCount = _.countBy(gradesAr, (obj) => {
    const gObj = getGradeObject(
      obj.grade,
      climbType,
      gradeType,
      gym,
      gradeColor,
      gradeName,
    )
    return gObj?.value
  })
  const dataset = _.map(gradeValues, (value) => {
    if (gradesCount[value]) {
      return gradesCount[value]
    }
    return 0
  })
  const maxVal = _.max(dataset)

  const barData = dataset.map((val) => {
    return {
      value: val,
      svg: {
        fill: maxVal === val ? highlightColor : headingColor,
        textAnchor: "middle",
      },
    }
  })
  return {
    barData,
    dataset,
    maxVal,
    gradeColors,
    gradeImages,
    gradeRange,
  }
}

export function convertValueLabelsToDonutData(data) {
  const colors = getBlueHues(data.length ?? 0)
  const donutData = data.map((obj, ind) => {
    return {
      ...obj,
      size: obj.value,
      color: colors[ind],
      colorValue: colors[ind],
      radius: 25,
      title: `${obj.label} (${obj.value})`,
      labelStyle: {
        display: "none",
      },
    }
  })
  return {
    children: donutData,
  }
}

export function convertValueLabelsToBarData(data) {
  const colors = getBlueHues(data.length ?? 0)
  const barData = data.map((obj, ind) => {
    return {
      ...obj,
      y: Number(obj.value ?? 0),
      x: obj.label,
      value: Number(obj.value ?? 0),
      color: colors[ind],
      colorValue: colors[ind],
    }
  })
  return barData
}
